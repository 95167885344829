import React, { useState } from "react"
import InputRange from 'react-input-range';

import { useStaticQuery, graphql, Link } from "gatsby"

const ApartmentSearch = () => {
    const AcfQuery = useStaticQuery(
		graphql`
			query ApartmentsRangeQuery {
				allWordpressPage(filter: {title: {eq: "Mieszkania"}}) {
					edges {
						node {
							acf {
								apartments_list {
									floor
									area
								}
							}
						}
					}
				}
			}
		`
    );
    
    const apartments = AcfQuery.allWordpressPage.edges[0].node.acf.apartments_list,
          lowestFloor = Math.min(...apartments.map(item => item.floor)),
          highestFloor = Math.max(...apartments.map(item => item.floor)),
          lowstArea = Math.min(...apartments.map(item => parseFloat(item.area.replace(',', ".")))),
          highestArea = Math.max(...apartments.map(item => parseFloat(item.area.replace(',', "."))));

    const [areaRange, setAreaRange] = useState({value: {min: Math.floor(lowstArea), max: Math.ceil(highestArea)}});
    const [floorRange, setFloorRange] = useState({value: {min: lowestFloor, max: highestFloor}});

    return (
        <section className="apartment-search background--gray">
            <div className="container">
                <div className="row">
                    <div className="col-12 apartment-search__holder">
                        <div className="apartment-search__item">
                            <h3 className="item__title">
                                Powierzchnia:
                            </h3>
                            <InputRange
                                allowSameValues={true}
                                maxValue={ Math.ceil(highestArea) }
                                minValue={ Math.floor(lowstArea) }
                                value={ areaRange.value }
                                onChange={ value => setAreaRange({ value }) } 
                            />
                        </div>
                        <div className="apartment-search__item">
                            <h3 className="item__title">
                                Piętro:
                            </h3>
                            <InputRange
                                allowSameValues={true}
                                maxValue={ highestFloor }
                                minValue={ lowestFloor }
                                value={floorRange.value}
                                onChange={value => setFloorRange({ value })} 
                            />
                        </div>
                        <div className="apartment-search__item apartment-search__item--link">
                            <Link
                                className="btn btn--search"
                                to="/mieszkania/"
                                state={{ 
                                    fromSearch: true,
                                    areaStart: areaRange.value.min, 
                                    areaEnd: areaRange.value.max, 
                                    floorStart: floorRange.value.min, 
                                    floorEnd: floorRange.value.max }}
                            >
                                Wyszukaj
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ApartmentSearch
